import { logger } from "../Logger";
import { ApiRequestReturnType } from "../types";
import { ErrorMessage } from "../types/CommonTypes";

/**
 * Check if a string is a valid json
 */
export const isJson = (jsonString: string) => {
  try {
    const jsonObject = JSON.parse(jsonString);
    return [true, jsonObject];
  } catch (error) {
    return [false, undefined];
  }
};

/**
 * Chek if a property exists
 * @param key
 * @param x
 * @returns returns is key is in x
 */
export const has = <K extends string>(key: K, x: object): x is { [key in K]: unknown } => {
  try {
    return key in x;
  } catch (e) {
    return false;
  }
};

/**
 * Evaluate an error from a fetch response
 * @param response response in error state (statusCode < 200  or > 204) from a fetch call
 * @param defaultMessage default error message if no error message is found
 * @returns an error object with title, message and status code
 */
export const evaluateError = async (response: Response, correlationId: string, defaultMessage?: string) => {
  if (!response) return { title: 'No response error', message: defaultMessage || 'Unknown error', statusCode: 500, correlationId: correlationId };

  const errorText: string = await response.text();
  const statusCode: number = response.status;
  const [isValidJson, json] = isJson(errorText);

  let title: string;
  if (errorText) {
    if (errorText.indexOf('\r\n') > 0) {
      title = errorText.substring(0, errorText.indexOf('\r\n'));
    } else {
      title = errorText;
    }
  } else {
    title = 'Unknown error';
  }
  let message: string = errorText || defaultMessage || 'Unknown error';

  if (isValidJson) {
    if (has('title', json)) title = json.title as string;
    else if (has('cause', json)) title = json.cause as string;
    else if (has('label', json)) title = json.label as string;
    else if (has('caption', json)) title = json.caption as string;

    if (has('message', json)) message = json.message as string;
    else if (has('detail', json)) message = json.detail as string;
    else if (has('details', json)) message = json.details as string;
    else if (has('error', json)) message = json.error as string;
    else if (has('errors', json)) message = json.errors as string;
    else if (has('text', json)) message = json.text as string;
    else if (has('stack', json)) message = json.stack as string;
  }

  return { title, message, statusCode, correlationId };
};

/**
 * Transform a UTC date time string to a local date time string
 * Input string could be '28-Mar-2023 08:06'
 * if local time is UTC+2, output will be '28-Mar-2023 10:06'
 */
export const utcDateTimeToUserDateTime = (inputDateTime: string | undefined) => {
  if (inputDateTime === undefined) return '';
  const dt = new Date(inputDateTime);
  if (isNaN(dt.getTime())) return '';
  const dtAsUTC = new Date(dt.getTime() - dt.getTimezoneOffset() * 60000);

  return dtAsUTC.toLocaleString([], { year: 'numeric', month: 'short', day: '2-digit', hour: 'numeric', minute: 'numeric' });
};

/**@function getResponseUsingResponseType
 * @description method to get the response based on the response type in the request.
 * @param returnType response type value present in the request.
 * @param response response of the API.
 * @returns response parsed based on the response type.
 */
export const getResponseUsingResponseType = async(returnType: ApiRequestReturnType | undefined, response: Response): Promise<any> => {
  try {
    switch (returnType) {
      case ApiRequestReturnType.text:
        return await response.text();
      case ApiRequestReturnType.blob:
        return await response.blob();
      case ApiRequestReturnType.json:
      default:
        return await response.json();
    }
  } catch(error) {
    logger.logError("Failed to extract response.");
    return undefined;
  }
}

/**@function handleTcTeamsAPIError
 * @description method to handle the errors from the TcTeams API.
 * @param requestUrl url of the request made to TcTeams.
 * @param correlationId correlation id of the request.
 * @param teamsContext teams context.
 * @param response response of the request made to TcTeams.
 * @returns {ErrorMessage} structure containing the errors.
 */
export const handleTcTeamsAPIError = async(requestUrl: string, correlationId: string, response: Response): Promise<ErrorMessage> => {
  const errorMessage: ErrorMessage = await evaluateError(response, correlationId, `Unknown error for request ${requestUrl}`);
  try {
    // If the error occured in tcBroker the details will be available on the message.resultStatus object, hence we need to parse the JSON.
    // Else, if the error occured in tcTeamsApi, the returned JSON will include already include the StatusCode and Message properties.
    if(errorMessage!==null && errorMessage!== undefined && isJson(errorMessage.message)[0] === true){
      const errorMessageJson: any = JSON.parse(errorMessage.message);
      errorMessage.message =
        errorMessageJson?.resultStatus?.statusText;
      errorMessage.statusCode =
        errorMessageJson?.resultStatus?.httpStatusCode;
    }
  } catch (e) {
    logger.logWarning(`Failed to parse the error message. Message: ${errorMessage?.message}`);
  }
  logger.logError(`Error in the request ${requestUrl}. Error: ${errorMessage?.message}`);
  return errorMessage;
}

export const formatStringWithArgs = (template: string, ...args: string[]): string => {
  return template.replace(/{(\d+)}/g, (match, number) => {
      return typeof args[number] !== 'undefined' ? args[number] : match;
  });
}
